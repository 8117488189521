import BasePlugin from '../BasePlugin'

export default class FillIssuesListCommand extends BasePlugin {
  async execute () {
    let petitionId = {
      'petitionId': this.context.getModel().id
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/FillIssuesListCommand`,
      petitionId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      Object.values(this.context.getDashboardComponents()).forEach(function (item) {
        if (item[0].id === 3495) {
          item[0].loadData()
        }
      })
      done()
    })
  }
}
